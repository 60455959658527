




















































































































































import { UserDetailResponse } from "@/api/user/types/Responses";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "UserDetail",
  props: {
    user: {
      type: Object as PropType<UserDetailResponse>,
      required: true,
    },
  },
  computed: {
    avatarUrl(): string {
      return this.user.avatar_url !== null ? this.user.avatar_url : "assets/avatar/avatar.svg";
    },
  },
});
