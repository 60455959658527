










































































































































































































































































import { AllItems } from "@/api/AbstractClient";
import EnumerationClient from "@/api/enum/EnumerationClient";
import FamilyClient from "@/api/family/FamilyClient";
import { FamilyResponse } from "@/api/family/types/Responses";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import SchoolClient from "@/api/school/SchoolClient";
import { SchoolResponse } from "@/api/school/types/Responses";
import { UserRequest } from "@/api/user/types/Requests";
import { AuthorizedPersonTypeResponse, RoleResponse, UserDetailResponse } from "@/api/user/types/Responses";
import FamilyListItem from "@/components/users/form/misc/FamilyListItem.vue";
import AuthorizedPersonType from "@/enums/AuthorizedPersonType";
import Role from "@/enums/Role";
import _ from "lodash";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { email, required, requiredIf } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "UserForm",
  components: { FamilyListItem },
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object as PropType<UserDetailResponse>,
      required: false,
    },
    schoolId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyRoles: false,
    isBusyAuthorizedPersonTypes: false,
    isBusySchools: false,
    isBusyFamilies: 0,
    isBusySchoolYears: false,
    isBusySchoolClasses: 0,
    // enums
    roles: [] as RoleResponse[],
    authorizedPersonTypes: [] as AuthorizedPersonTypeResponse[],
    schools: [] as SchoolResponse[],
    schoolClasses: [] as SchoolClassResponse[],
    families: [] as FamilyResponse[],
    // filter families by
    filterFamiliesBy: "",
    // form data
    form: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      roles: [],
      founder: {
        schools: [],
      },
      admin: {
        schools: [],
      },
      teacher: {
        school: null,
        school_classes: [],
      },
      authorized_person: {
        family: null,
        authorized_person_type: null,
      },
    } as UserRequest,
  }),
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: {
        required: requiredIf(function(this: any): boolean {
          return this.form.roles.includes(Role.ADMIN)
            || this.form.roles.includes(Role.TEACHER)
            || (this.form.roles.includes(Role.AUTHORIZED_PERSON) && this.form.authorized_person?.authorized_person_type === AuthorizedPersonType.LEGAL_REPRESENTATIVE);
        }),
        email,
      },
      phone: {},
      roles: { required },
      founder: {
        schools: {
          required: requiredIf(function(this: any): boolean {
            return this.requireFounderFields;
          }),
        },
      },
      admin: {
        schools: {
          required: requiredIf(function(this: any): boolean {
            return this.requireAdminFields;
          }),
        },
      },
      teacher: {
        school: {
          required: requiredIf(function(this: any): boolean {
            return this.requireTeacherFields;
          }),
        },
        school_classes: {},
      },
      authorized_person: {
        family: {
          required: requiredIf(function(this: any): boolean {
            return this.requireAuthorizedPersonFields;
          }),
        },
        authorized_person_type: {
          required: requiredIf(function(this: any): boolean {
            return this.requireAuthorizedPersonFields;
          }),
        },
      },
    },
  },
  watch: {
    "form.teacher.school": function(newSchoolId?: number): void {
      if (newSchoolId) {
        this.fetchSchoolClasses(newSchoolId);
      }
    },
    filterFamiliesBy: _.debounce(function(this: any, value?: string): void {
      if (value && value.length > 0) {
        this.fetchFamilies();
      } else {
        this.families = [];
      }
    }, 250),
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit
        || this.isBusySchools
        || this.isBusyRoles
        || this.isBusyAuthorizedPersonTypes
        || this.isBusySchoolYears
        || this.isBusySchoolClasses > 0;
    },
    requireFounderFields(this: any): boolean {
      return this.form.roles.includes(Role.FOUNDER.valueOf());
    },
    requireAdminFields(this: any): boolean {
      return this.form.roles.includes(Role.ADMIN.valueOf());
    },
    requireTeacherFields(this: any): boolean {
      return this.form.roles.includes(Role.TEACHER.valueOf());
    },
    requireAuthorizedPersonFields(this: any): boolean {
      return this.form.roles.includes(Role.AUTHORIZED_PERSON.valueOf());
    },
    rolesFiltered(): any[] {
      return this.roles.map((role: RoleResponse): any => ({
        value: role.id,
        text: role.name,
        disabled: role.id === Role.FOUNDER.valueOf(),
      }));
    },
    groupedSchoolClasses(): any[] {
      if (this.schoolClasses.length === 0) {
        return [];
      }
      const groupedSchoolClasses = {} as any;
      this.schoolClasses.forEach(schoolClass => {
        if (!groupedSchoolClasses[schoolClass.schoolYear.name]) {
          groupedSchoolClasses[schoolClass.schoolYear.name] = [];
        }
        groupedSchoolClasses[schoolClass.schoolYear.name].push(schoolClass);
      });
      const items = [];
      for (const [schoolYearName, schoolClasses] of Object.entries<any>(groupedSchoolClasses)) {
        items.push({ header: schoolYearName });
        schoolClasses.forEach((schoolClass: SchoolClassResponse) => {
          items.push({ id: schoolClass.id, name: schoolClass.name });
        });
      }
      return items;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("users.create_user")
        : this.$t("users.edit_user");
    },
    firstNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.first_name?.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push(this.$t("users.validation.first_name.required"));
      return errors;
    },
    lastNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.last_name?.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push(this.$t("users.validation.last_name.required"));
      return errors;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("users.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("users.validation.email.email"));
      return errors;
    },
    phoneErrors(): TranslateResult[] {
      return [];
    },
    rolesErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.roles?.$dirty) return errors;
      !this.$v.form.roles.required && errors.push(this.$t("users.validation.roles.required"));
      return errors;
    },
    schoolsErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.admin?.schools?.$dirty) return errors;
      !this.$v.form.admin.schools.required && errors.push(this.$t("users.validation.schools.required"));
      return errors;
    },
    schoolErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.teacher?.school?.$dirty) return errors;
      !this.$v.form.teacher.school.required && errors.push(this.$t("users.validation.school.required"));
      return errors;
    },
    schoolClassesErrors(): TranslateResult[] {
      return [];
    },
    authorizedPersonFamilyErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.authorized_person?.family?.$dirty) return errors;
      !this.$v.form.authorized_person.family.required && errors.push(this.$t("users.validation.family.required"));
      return errors;
    },
    authorizedPersonAuthorizedPersonTypeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.authorized_person?.authorized_person_type?.$dirty) return errors;
      !this.$v.form.authorized_person.authorized_person_type.required && errors.push(this.$t("users.validation.authorized_person_type.required"));
      return errors;
    },
  },
  methods: {
    initDefaults(): void {
      if (this.defaults) {
        this.form.first_name = this.defaults.first_name;
        this.form.last_name = this.defaults.last_name;
        this.form.email = this.defaults.email;
        this.form.phone = this.defaults.phone;
        this.form.roles = this.defaults.roles.filter((role: string): boolean => role !== Role.USER);
        if (this.defaults.founder) {
          this.form.founder = {
            schools: this.defaults.founder.schools.map((school: SchoolResponse) => school.id),
          };
        }
        if (this.defaults.admin) {
          this.form.admin = {
            schools: this.defaults.admin.schools.map((school: SchoolResponse) => school.id),
          };
        }
        if (this.defaults.teacher) {
          this.form.teacher = {
            school: this.defaults.teacher.school.id,
            school_classes: this.defaults.teacher.school_classes.map((schoolClass: SchoolClassResponse) => schoolClass.id),
          };
        }
        if (this.defaults.authorized_person) {
          this.form.authorized_person = {
            family: this.defaults.authorized_person.family_id,
            authorized_person_type: this.defaults.authorized_person.authorized_person_type.id,
          };
        }
      }
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: UserRequest = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone,
        roles: this.form.roles.filter((role: string): boolean => role !== Role.FOUNDER),
      };
      if (this.form.roles.includes(Role.ADMIN.valueOf()) && this.form.admin?.schools) {
        request.admin = {
          schools: this.form.admin.schools,
        };
      }
      if (this.form.roles.includes(Role.TEACHER.valueOf()) && this.form.teacher?.school && this.form.teacher?.school_classes) {
        request.teacher = {
          school: this.form.teacher.school,
          school_classes: this.form.teacher.school_classes,
        };
      }
      if (this.form.roles.includes(Role.AUTHORIZED_PERSON.valueOf()) && this.form.authorized_person?.family && this.form.authorized_person?.authorized_person_type) {
        request.authorized_person = {
          family: this.form.authorized_person.family,
          authorized_person_type: this.form.authorized_person.authorized_person_type,
        };
      }

      this.$emit("onSubmit", request);
    },
    onClose(): void {
      this.$parent.$emit("onClose");
    },
    fetchRoles(): void {
      this.isBusyRoles = true;
      EnumerationClient.getRoles()
        .then((response) => {
          this.roles = response;
        })
        .finally(() => {
          this.isBusyRoles = false;
        });
    },
    fetchAuthorizedPersonTypes(): void {
      this.isBusyAuthorizedPersonTypes = true;
      EnumerationClient.getAuthorizedPersonTypes()
        .then((response) => {
          this.authorizedPersonTypes = response;
        })
        .finally(() => {
          this.isBusyAuthorizedPersonTypes = false;
        });
    },
    fetchSchools(): void {
      this.isBusySchools = true;
      SchoolClient.getSchools(new AllItems())
        .then((response) => {
          this.schools = response.items;
        })
        .finally(() => {
          this.isBusySchools = false;
        });
    },
    fetchFamilies(): void {
      let filter = "";
      if (!_.isEmpty(this.filterFamiliesBy)) {
        filter = "fulltext:\"" + this.filterFamiliesBy + "\"";
      }
      this.isBusyFamilies += 1;
      FamilyClient.getFamilies(new AllItems(filter))
        .then((response) => {
          this.families = response.items;
        })
        .finally(() => {
          this.isBusyFamilies -= 1;
        });
    },
    fetchSchoolClasses(schoolId: number): void {
      this.schoolClasses = [];
      this.isBusySchoolYears = true;
      SchoolYearClient.getSchoolYears(schoolId, new AllItems())
        .then((response) => {
          response.items.forEach((schoolYear: SchoolYearResponse) => {
            this.isBusySchoolClasses += 1;
            SchoolClassClient.getSchoolClasses(this.schoolId, schoolYear.id, new AllItems())
              .then((response) => {
                response.items.forEach((schoolClass: SchoolClassResponse) => {
                  this.schoolClasses.push(schoolClass);
                });
              })
              .finally(() => {
                this.isBusySchoolClasses -= 1;
              });
          });
        })
        .finally(() => {
          this.isBusySchoolYears = false;
        });
    },
  },
  created(): void {
    this.fetchRoles();
    this.fetchAuthorizedPersonTypes();
    this.fetchSchools();
    this.fetchFamilies();
    this.initDefaults();
  },
});
