





























import SchoolUsersTable from "@/components/users/table/SchoolUsersTable.vue";
import Vue from "vue";

export default Vue.extend({
  name: "SchoolUsers",
  components: { SchoolUsersTable },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // tab
    tab: null,
  }),
  computed: {
    employeesBaseFilter(): string {
      return "or:(" + [
        "isnotnull:admin.id",
        "isnotnull:teacher.id",
        "isnotnull:founder.id",
      ].join(";") + ")";
    },
    authorizedPersonsBaseFilter(): string {
      return "isnotnull:authorized_person.id";
    },
  },
  methods: {
    refresh(): void {
      if (this.$refs["employees-table"]) {
        (this.$refs["employees-table"] as any).refresh();
      }
      if (this.$refs["authorized-persons-table"]) {
        (this.$refs["authorized-persons-table"] as any).refresh();
      }
    },
  },
});
