










import Vue from "vue";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import SchoolClient from "@/api/school/SchoolClient";
import AbstractClient from "@/api/AbstractClient";
import _ from "lodash";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import { SchoolResponse } from "@/api/school/types/Responses";

export default Vue.extend({
  name: "SchoolsTable",
  components: { DataTable },
  computed: {
    client(): AbstractClient {
      return new SchoolClient();
    },
    clientFunction(): any {
      return SchoolClient.getSchools;
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("schools.name")),
          value: "name",
          filter: {
            key: "name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].name"],
          },
        },
        {
          text: _.toString(this.$t("schools.ico")),
          value: "ico",
          filter: {
            key: "ico",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].ico"],
          },
        },
        {
          text: _.toString(this.$t("schools.izo")),
          value: "izo",
          filter: {
            key: "izo",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].izo"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (school: SchoolResponse): void => {
            this.$router.push({ name: "school", params: { schoolId: _.toString(school.id) } });
          },
        },
      ];
    },
  },
  methods: {
    refresh() {
      (this.$refs["table"] as any).refresh();
    },
  },
});
