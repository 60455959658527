








import Vue, { PropType } from "vue";
import SchoolForm from "@/components/schools/form/SchoolForm.vue";
import { UpdateSchoolRequest } from "@/api/school/types/Requests";
import SchoolClient from "@/api/school/SchoolClient";
import { SchoolResponse } from "@/api/school/types/Responses";

export default Vue.extend({
  name: "EditSchoolForm",
  components: { SchoolForm },
  props: {
    school: {
      type: Object as PropType<SchoolResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: UpdateSchoolRequest): void {
      this.isBusySubmit = true;
      SchoolClient.updateSchool(this.school.id, request)
        .then((response: SchoolResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
