


































































































import Vue, { PropType } from "vue";
import { SchoolResponse } from "@/api/school/types/Responses";

export default Vue.extend({
  name: "SchoolDetail",
  props: {
    school: {
      type: Object as PropType<SchoolResponse>,
      required: true,
    },
  },
});
