var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',{attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":_vm.school ? _vm.school.name : null}}),_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$isFounder())?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":"","disabled":_vm.school === null}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("schools.edit_school"))+" ")],1):_vm._e()]}}]),model:{value:(_vm.editSchoolDialog),callback:function ($$v) {_vm.editSchoolDialog=$$v},expression:"editSchoolDialog"}},[(_vm.editSchoolDialog)?_c('edit-school-form',{attrs:{"school":_vm.school},on:{"onClose":function($event){_vm.editSchoolDialog = false},"onSubmit":_vm.onSubmitEditSchool}}):_vm._e()],1),(_vm.$isFounder())?_c('v-btn',{staticClass:"mb-3",attrs:{"color":"error","exact":"","text":"","disabled":_vm.school === null,"loading":_vm.isBusyDelete},on:{"click":_vm.onDeleteSchool}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t("schools.delete_school"))+" ")],1):_vm._e()],1),(_vm.school !== null)?_c('school-detail',{staticClass:"mb-3",attrs:{"school":_vm.school}}):_c('v-skeleton-loader',{staticClass:"mb-3",attrs:{"type":"card"}}),_c('page-subtitle',{attrs:{"title":_vm.$t('users.users')}}),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.$isSuperAdmin())?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("users.create_user"))+" ")],1):_vm._e()]}}]),model:{value:(_vm.createUserDialog),callback:function ($$v) {_vm.createUserDialog=$$v},expression:"createUserDialog"}},[(_vm.createUserDialog)?_c('create-user-form',{attrs:{"school-id":_vm.schoolId},on:{"onClose":function($event){_vm.createUserDialog = false},"onSubmit":_vm.onSubmitCreateUser}}):_vm._e()],1),_c('school-users',{ref:"school-users",staticClass:"mb-3",attrs:{"school-id":_vm.schoolId}}),_c('page-subtitle',{attrs:{"title":_vm.$t('school_years.school_years')}}),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.$isSuperAdmin())?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("school_years.create_school_year"))+" ")],1):_vm._e()]}}]),model:{value:(_vm.createSchoolYearDialog),callback:function ($$v) {_vm.createSchoolYearDialog=$$v},expression:"createSchoolYearDialog"}},[(_vm.createSchoolYearDialog)?_c('create-school-year-form',{attrs:{"school-id":_vm.schoolId},on:{"onClose":function($event){_vm.createSchoolYearDialog = false},"onSubmit":_vm.onSubmitCreateSchoolYear}}):_vm._e()],1),_c('school-years-table',{ref:"school-years-table",attrs:{"school-id":_vm.schoolId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }