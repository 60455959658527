































import PageTitle from "@/components/layout/PageTitle.vue";
import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import SchoolsTable from "@/components/schools/table/SchoolsTable.vue";
import CreateSchoolForm from "@/components/schools/form/CreateSchoolForm.vue";

export default Vue.extend({
  name: "Schools",
  components: { PageTitle, CreateSchoolForm, SchoolsTable, DefaultLayout },
  data: () => ({
    // dialog
    dialog: false,
  }),
  methods: {
    onSubmit(): void {
      this.dialog = false;
      (this.$refs["table"] as any).refresh();
      this.$snackbarSuccess(this.$t("schools.school_created"));
    },
  },
});
