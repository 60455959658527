











































































































































































import Vue, { PropType } from "vue";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import EnumerationClient from "@/api/enum/EnumerationClient";
import { CountryResponse } from "@/api/common/types/Responses";
import { SchoolResponse } from "@/api/school/types/Responses";
import { TranslateResult } from "vue-i18n";
import { SchoolRequest } from "@/api/school/types/Requests";

export default Vue.extend({
  name: "SchoolForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object as PropType<SchoolResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyCountries: false,
    // enums
    countries: [] as CountryResponse[],
    // form data
    form: {
      name: "",
      ico: "",
      izo: "",
      email: "",
      phone: "",
      address: {
        street: "",
        house_number: "",
        city: "",
        postal_code: "",
        country: "CZ", // Czech republic by default
      },
    } as SchoolRequest,
  }),
  validations: {
    form: {
      name: { required },
      ico: { required },
      izo: { required },
      email: { required, email },
      phone: { required },
      address: {
        street: { required },
        house_number: { required },
        city: { required },
        postal_code: { required },
        country: { required },
      },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyCountries;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("schools.create_school")
        : this.$t("schools.edit_school");
    },
    nameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.name?.$dirty) return errors;
      !this.$v.form.name.required && errors.push(this.$t("schools.validation.name.required"));
      return errors;
    },
    icoErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.ico?.$dirty) return errors;
      !this.$v.form.ico.required && errors.push(this.$t("schools.validation.ico.required"));
      return errors;
    },
    izoErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.izo?.$dirty) return errors;
      !this.$v.form.izo.required && errors.push(this.$t("schools.validation.izo.required"));
      return errors;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("schools.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("schools.validation.email.email"));
      return errors;
    },
    phoneErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.phone?.$dirty) return errors;
      !this.$v.form.phone.required && errors.push(this.$t("schools.validation.phone.required"));
      return errors;
    },
    streetErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form?.address?.street?.$dirty) return errors;
      !this.$v.form.address.street.required && errors.push(this.$t("schools.validation.street.required"));
      return errors;
    },
    houseNumberErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form?.address?.house_number?.$dirty) return errors;
      !this.$v.form.address.house_number.required && errors.push(this.$t("schools.validation.house_number.required"));
      return errors;
    },
    cityErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form?.address?.city?.$dirty) return errors;
      !this.$v.form.address.city.required && errors.push(this.$t("schools.validation.city.required"));
      return errors;
    },
    postalCodeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form?.address?.postal_code?.$dirty) return errors;
      !this.$v.form.address.postal_code.required && errors.push(this.$t("schools.validation.postal_code.required"));
      return errors;
    },
    countryErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form?.address?.country?.$dirty) return errors;
      !this.$v.form.address.country.required && errors.push(this.$t("schools.validation.country.required"));
      return errors;
    },
  },
  methods: {
    initDefaults(): void {
      if (this.defaults) {
        this.form.name = this.defaults.name;
        this.form.ico = this.defaults.ico;
        this.form.izo = this.defaults.izo;
        this.form.email = this.defaults.email;
        this.form.phone = this.defaults.phone;
        this.form.address.street = this.defaults.address.street;
        this.form.address.house_number = this.defaults.address.house_number;
        this.form.address.city = this.defaults.address.city;
        this.form.address.postal_code = this.defaults.address.postal_code;
        this.form.address.country = this.defaults.address.country.code;
      }
    },
    fetchCountries(): void {
      this.isBusyCountries = true;
      EnumerationClient.getCountries()
        .then((response: CountryResponse[]) => {
          this.countries = response;
        })
        .finally(() => {
          this.isBusyCountries = false;
        });
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: SchoolRequest = {
        name: this.form.name,
        ico: this.form.ico,
        izo: this.form.izo,
        email: this.form.email,
        phone: this.form.phone,
        address: {
          street: this.form.address.street,
          house_number: this.form.address.house_number,
          city: this.form.address.city,
          postal_code: this.form.address.postal_code,
          country: this.form.address.country,
        },
      };

      this.$emit("onSubmit", request);
    },
    onClose(): void {
      this.$parent.$emit("onClose");
    },
  },
  created(): void {
    this.fetchCountries();
    this.initDefaults();
  },
});
