


















import Vue from "vue";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import AbstractClient from "@/api/AbstractClient";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import _ from "lodash";

export default Vue.extend({
  name: "SchoolYearsTable",
  components: { DataTable },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    client(): AbstractClient {
      return new SchoolYearClient();
    },
    clientFunction(): any {
      return SchoolYearClient.getSchoolYears;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("school_years.name")),
          value: "name",
          filter: {
            key: "name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].name"],
          },
        },
        {
          text: _.toString(this.$t("school_years.date_from")),
          value: "date_from",
          filter: {
            key: "date_from",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].date_from"],
          },
        },
        {
          text: _.toString(this.$t("school_years.date_to")),
          value: "date_to",
          filter: {
            key: "date_to",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].date_to"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (schoolYear: SchoolYearResponse): void => {
            this.$router.push({
              name: "schoolYear",
              params: {
                schoolId: _.toString(this.schoolId),
                schoolYearId: _.toString(schoolYear.id),
              },
            });
          },
        },
      ];
    },
  },
  methods: {
    refresh() {
      (this.$refs["table"] as any).refresh();
    },
  },
});
