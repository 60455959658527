



















import { ApiTokenResponse } from "@/api/security/types/Responses";
import { Identity } from "@/utils/Identity";
import Vue from "vue";
import AbstractClient from "@/api/AbstractClient";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import _ from "lodash";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import SchoolUserClient from "@/api/school/SchoolUserClient";
import { UserDetailResponse, UserResponse } from "@/api/user/types/Responses";

export default Vue.extend({
  name: "SchoolUsersTable",
  components: { DataTable },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    baseFilter: {
      type: String,
      required: true,
    },
  },
  computed: {
    client(): AbstractClient {
      return new SchoolUserClient();
    },
    clientFunction(): any {
      return SchoolUserClient.getUsers;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("users.first_name")),
          value: "first_name",
          filter: {
            key: "first_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].first_name"],
          },
        },
        {
          text: _.toString(this.$t("users.last_name")),
          value: "last_name",
          filter: {
            key: "last_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].last_name"],
          },
        },
        {
          text: _.toString(this.$t("users.email")),
          value: "email",
          filter: {
            key: "email",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].email"],
          },
        },
        {
          text: _.toString(this.$t("users.phone")),
          value: "phone",
          filter: {
            key: "phone",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].phone"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "login_as",
          icon: "mdi-login",
          tooltip: this.$t("users.login_as"),
          disabled: () => this.$store.getters["ApiToken/isImpersonated"],
          onClick: (userToLoginAs: UserResponse): Promise<ApiTokenResponse> => {
            return Identity.onChangeIdentity(userToLoginAs.id)
              .then((response) => {
                // snackbar
                this.$snackbarSuccess(this.$t("layout.identity_changed"));
                // go to user's dashboard
                this.$router.push({ name: "dashboard" });
                return response;
              });
          },
          show: () => this.$isSuperAdmin() || this.$isAdmin(),
        },
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (user: UserDetailResponse): void => {
            this.$router.push({
              name: "schoolUser",
              params: {
                schoolId: _.toString(this.schoolId),
                userId: _.toString(user.id),
              },
            });
          },
        },
      ];
    },
  },
  methods: {
    refresh(): void {
      (this.$refs["table"] as any).refresh();
    },
  },
});
