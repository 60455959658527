




import Vue, { PropType } from "vue";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import SchoolYearForm from "@/components/school-years/form/SchoolYearForm.vue";
import { UpdateSchoolYearRequest } from "@/api/school-year/types/Requests";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";

export default Vue.extend({
  name: "EditSchoolYearForm",
  components: { SchoolYearForm },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: UpdateSchoolYearRequest): void {
      this.isBusySubmit = true;
      SchoolYearClient.updateSchoolYear(this.schoolId, this.schoolYear.id, request)
        .then((response: SchoolYearResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
