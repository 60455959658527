















































import Vue, { PropType } from "vue";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import moment from "moment";

export default Vue.extend({
  name: "SchoolYearDetail",
  props: {
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
  },
  computed: {
    dateFromFormatted(): string {
      return moment(this.schoolYear.date_from).format("LL");
    },
    dateToFormatted(): string {
      return moment(this.schoolYear.date_to).format("LL");
    },
  },
});
