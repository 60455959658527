







import Vue from "vue";
import SchoolForm from "@/components/schools/form/SchoolForm.vue";
import SchoolClient from "@/api/school/SchoolClient";
import { CreateSchoolRequest } from "@/api/school/types/Requests";
import { SchoolResponse } from "@/api/school/types/Responses";

export default Vue.extend({
  name: "CreateSchoolForm",
  components: { SchoolForm },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: CreateSchoolRequest): void {
      this.isBusySubmit = true;
      SchoolClient.createSchool(request)
        .then((response: SchoolResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
