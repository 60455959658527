








import Vue from "vue";
import UserForm from "@/components/users/form/UserForm.vue";
import UserClient from "@/api/user/UserClient";
import { CreateUserRequest } from "@/api/user/types/Requests";
import { UserDetailResponse } from "@/api/user/types/Responses";

export default Vue.extend({
  name: "CreateUserForm",
  components: { UserForm },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: CreateUserRequest): void {
      this.isBusySubmit = true;
      UserClient.createUser(request)
        .then((response: UserDetailResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
