








































































































import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import EditSchoolForm from "@/components/schools/form/EditSchoolForm.vue";
import SchoolClient from "@/api/school/SchoolClient";
import { SchoolResponse } from "@/api/school/types/Responses";
import _ from "lodash";
import PageTitle from "@/components/layout/PageTitle.vue";
import SchoolDetail from "@/components/schools/SchoolDetail.vue";
import SchoolYearsTable from "@/components/school-years/table/SchoolYearsTable.vue";
import CreateSchoolYearForm from "@/components/school-years/form/CreateSchoolYearForm.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";
import SchoolUsers from "@/components/schools/SchoolUsers.vue";
import CreateUserForm from "@/components/users/form/CreateUserForm.vue";

export default Vue.extend({
  name: "School",
  components: {
    CreateUserForm,
    SchoolUsers,
    PageSubtitle,
    CreateSchoolYearForm,
    SchoolYearsTable,
    SchoolDetail,
    PageTitle,
    EditSchoolForm,
    DefaultLayout,
  },
  data: () => ({
    // loading
    isBusySchool: false,
    isBusyDelete: false,
    // dialog
    editSchoolDialog: false,
    createSchoolYearDialog: false,
    createUserDialog: false,
    // data
    school: null as SchoolResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySchool || this.isBusyDelete;
    },
    schoolId(): number {
      return _.toInteger(this.$route.params.schoolId);
    },
  },
  methods: {
    onSubmitEditSchool(school: SchoolResponse): void {
      this.editSchoolDialog = false;
      this.school = school;
      this.$snackbarSuccess(this.$t("schools.school_updated"));
    },
    onSubmitCreateSchoolYear(): void {
      this.createSchoolYearDialog = false;
      (this.$refs["school-years-table"] as any).refresh();
      this.$snackbarSuccess(this.$t("school_years.school_year_created"));
    },
    onSubmitCreateUser(): void {
      this.createUserDialog = false;
      (this.$refs["school-users"] as any).refresh();
      this.$snackbarSuccess(this.$t("users.user_created"));
    },
    onDeleteSchool(): void {
      if (!confirm(this.$t("schools.confirm_delete").toString())) {
        return;
      }
      this.isBusyDelete = true;
      SchoolClient.deleteSchool(this.schoolId)
        .then(() => {
          this.$snackbarSuccess(this.$t("schools.school_deleted"));
          this.$router.push({ name: "schools" });
        })
        .finally(() => {
          this.isBusyDelete = false;
        });
    },
    fetchSchool(): void {
      this.isBusySchool = true;
      SchoolClient.getSchool(this.schoolId)
        .then((response: SchoolResponse) => {
          this.school = response;
        })
        .finally(() => {
          this.isBusySchool = false;
        });
    },
  },
  created(): void {
    this.fetchSchool();
  },
});
