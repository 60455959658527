

















































































































import Vue, { PropType } from "vue";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { SchoolYearRequest } from "@/api/school-year/types/Requests";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default Vue.extend({
  name: "SchoolYearForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object as PropType<SchoolYearResponse>,
      required: false,
    },
  },
  data: () => ({
    // dialog
    dateFromDialog: false,
    dateToDialog: false,
    // form data
    form: {
      name: "",
      date_from: "",
      date_to: "",
    } as SchoolYearRequest,
  }),
  validations: {
    form: {
      name: { required },
      date_from: {
        required,
        valid: function(): boolean {
          return this.form.date_from && this.form.date_to
            ? moment(this.form.date_from).isBefore(moment(this.form.date_to))
            : true;
        },
      },
      date_to: {
        required,
        valid: function(): boolean {
          return this.form.date_from && this.form.date_to
            ? moment(this.form.date_from).isBefore(moment(this.form.date_to))
            : true;
        },
      },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("school_years.create_school_year")
        : this.$t("school_years.edit_school_year");
    },
    nameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.name?.$dirty) return errors;
      !this.$v.form.name.required && errors.push(this.$t("school_years.validation.name.required"));
      return errors;
    },
    dateFromErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_from?.$dirty) return errors;
      !this.$v.form.date_from.required && errors.push(this.$t("school_years.validation.date_from.required"));
      !this.$v.form.date_from.valid && errors.push(this.$t("school_years.validation.date_from.valid"));
      return errors;
    },
    dateToErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_to?.$dirty) return errors;
      !this.$v.form.date_to.required && errors.push(this.$t("school_years.validation.date_to.required"));
      !this.$v.form.date_to.valid && errors.push(this.$t("school_years.validation.date_to.valid"));
      return errors;
    },
    dateFromFormatted(): string {
      return this.form.date_from ? moment(this.form.date_from).format("LL") : "";
    },
    dateToFormatted(): string {
      return this.form.date_to ? moment(this.form.date_to).format("LL") : "";
    },
  },
  methods: {
    initDefaults(): void {
      if (this.defaults) {
        this.form.name = this.defaults.name;
        this.form.date_from = this.defaults.date_from.format("YYYY-MM-DD");
        this.form.date_to = this.defaults.date_to.format("YYYY-MM-DD");
      }
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: SchoolYearRequest = {
        name: this.form.name,
        date_from: this.form.date_from,
        date_to: this.form.date_to,
      };

      this.$emit("onSubmit", request);
    },
    onClose() {
      this.$parent.$emit("onClose");
    },
  },
  created(): void {
    this.initDefaults();
  },
});
