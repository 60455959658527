



















































import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import PageTitle from "@/components/layout/PageTitle.vue";
import EditSchoolYearForm from "@/components/school-years/form/EditSchoolYearForm.vue";
import _ from "lodash";
import SchoolYearDetail from "@/components/school-years/SchoolYearDetail.vue";

export default Vue.extend({
  name: "SchoolYear",
  components: { SchoolYearDetail, EditSchoolYearForm, PageTitle, DefaultLayout },
  data: () => ({
    // loading
    isBusySchoolYear: false,
    isBusyDelete: false,
    // dialog
    editSchoolYearDialog: false,
    // data
    schoolYear: null as SchoolYearResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySchoolYear || this.isBusyDelete;
    },
    schoolId(): number {
      return _.toInteger(this.$route.params.schoolId);
    },
    schoolYearId(): number {
      return _.toInteger(this.$route.params.schoolYearId);
    },
  },
  methods: {
    onSubmitEditSchoolYear(schoolYear: SchoolYearResponse): void {
      this.editSchoolYearDialog = false;
      this.schoolYear = schoolYear;
      this.$snackbarSuccess(this.$t("school_years.school_year_updated"));
    },
    onDeleteSchoolYear(): void {
      if (!confirm(this.$t("school_years.confirm_delete").toString())) {
        return;
      }
      this.isBusyDelete = true;
      SchoolYearClient.deleteSchoolYear(this.schoolId, this.schoolYearId)
        .then(() => {
          this.$snackbarSuccess(this.$t("school_years.school_year_deleted"));
          this.$router.push({ name: "school", params: { schoolId: _.toString(this.schoolId) } });
        })
        .finally(() => {
          this.isBusyDelete = false;
        });
    },
    fetchSchoolYear(): void {
      this.isBusySchoolYear = true;
      SchoolYearClient.getSchoolYear(this.schoolId, this.schoolYearId)
        .then((response: SchoolYearResponse) => {
          this.schoolYear = response;
        })
        .finally(() => {
          this.isBusySchoolYear = false;
        });
    },
  },
  created(): void {
    this.fetchSchoolYear();
  },
});
